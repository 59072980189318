<template lang="pug">
.Payment
  LoadingFSFB(v-if="preload")
  .ContainerHeader.pb-5
    .d-flex.align-items-center.justify-content-between
      img(
        v-if="!fromQR"
        src="@/assets/mobileFSFB/icn_backicn_back.png"
        alt="icn_backicn_back"
        @click="goBack"
      ).icn_backicn_back.ml-3.backButton
      div(v-else).icn_backicn_back.ml-3
      h3.fontTitleHeader.m-0(v-if="statusPayment == 'success'") Pago exitoso
      h3.fontTitleHeader.m-0(v-else-if="statusPayment == 'error'") Pago no exitoso
      h3.fontTitleHeader.m-0(v-else-if="statusPayment == 'outTime'") Fuera de horario
      h3.fontTitleHeader.m-0(v-else) Pendiente de pago
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.opacity-0.mr-3
  .ContainerData.px-2
    .card.mt-4
      h6(v-if="appointment && appointment.service").subTitle.text-start.pb-3.m-0: strong {{appointment.service.detail}}
      p.mb-2 {{doctorName}}
      .dateCard.d-flex.align-items-center.justify-content-between.p-3
        .d-flex.align-items-center
          svg(width='24' height='25' viewbox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M9.46056 5.62695V7.31721' stroke='#5AC3F0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M14.8297 5.62695V7.31721' stroke='#5AC3F0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M7.92462 6.33936H16.3411C16.7418 6.33936 17.1261 6.50672 17.4094 6.80462C17.6927 7.10251 17.8519 7.50655 17.8519 7.92784V16.4499C17.8519 16.8724 17.6923 17.2775 17.4081 17.5763C17.124 17.875 16.7387 18.0428 16.3369 18.0428H7.92041C7.51973 18.0428 7.13546 17.8755 6.85214 17.5776C6.56882 17.2797 6.40965 16.8756 6.40965 16.4543V7.92784C6.40965 7.71887 6.44887 7.51195 6.52506 7.31893C6.60124 7.12592 6.7129 6.95061 6.85363 6.80305C6.99436 6.65549 7.16141 6.53857 7.34519 6.459C7.52897 6.37944 7.72587 6.33878 7.92462 6.33936V6.33936Z' stroke='#5AC3F0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M6.41446 9.32617H17.8777' stroke='#5AC3F0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
          p.mb-0.parragraphCapitalize {{ citaFecha(appointment.date_appoinment) }} 
        .d-flex.align-items-center
          svg(width='24' height='25' viewbox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M10.9219 18.043C14.0735 18.043 16.6283 15.3567 16.6283 12.043C16.6283 8.72926 14.0735 6.04297 10.9219 6.04297C7.77031 6.04297 5.21545 8.72926 5.21545 12.043C5.21545 15.3567 7.77031 18.043 10.9219 18.043Z' stroke='#89D4F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M10.9219 12.0431V8.79297' stroke='#89D4F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M10.9219 12.043H12.871' stroke='#89D4F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
          p.mb-0 {{ citaHora(appointment.date_appoinment + ' ' +appointment.hour_appoinment) }}
      .dateCard.d-flex.align-items-center.p-3.mt-3
        p.mb-0 № de confirmación: {{ appointment.pac_num }}
    .card.card-2.mb-5
      div(v-if="statusPayment")
        h3(v-if="statusPayment === 'success'") Pago exitoso!
        h3(v-if="statusPayment === 'error'") El pago no fue exitoso...
        button(v-if="statusPayment === 'error'" @click="onSelectedThisProcedure(appointment)").buttonForm.d-block.w-100.mt-4
          span Pagar con asesor
        h3(v-if="statusPayment === 'outTime'") La cita esta fuera del horario
        button.buttonForm.d-block.w-100.mt-4(@click="checkTurn")
          span(v-if="loading && loading === 'checkTurn'")
            MiniSpinner
          span(v-else) Continuar
      div(v-else)
        h3 Selecciona la forma en que deseas pagar tu cita:
        button.buttonForm.d-block.w-100.mt-4(v-if="urlExist" @click="openAlert")
          span(v-if="loading && loading === 'externalPay'")
            MiniSpinner
          span(v-else) Pagar Online
        button(@click="onSelectedThisProcedure(appointment)").buttonForm.d-block.w-100.mt-4
          span Pagar con asesor

  //- Modals
  b-modal(id="pays-modal-mobileFSFB" hide-footer hide-header size="s" centered no-close-on-backdrop)
    .dateCard.d-flex.align-items-center.justify-content-between.p-3(style="text-align: center;")
      strong.mt-0.mb-0.fonterCurrentUser Al finalizar tu proceso de pago, recuerda descargar tu comprobante y regresar al comercio
    div
      button(@click="cancelPayAction" style="background: #aaaaaa").buttonForm.d-block.w-100.mt-2
        span Cancelar
      button(@click="externalPay(appointment)").buttonForm.d-block.w-100.mt-2
        span Continuar con Pago Online

</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

moment.locale("es") 

export default {
  name: "Payment",
  data() {
    return {
      statusPayment: null,
      loading: "",

      // Check url paymente exist
      urlExist: true
    };
  },
  computed: {
    ...mapState({
      citas: state => state.mobileFSFB.citas,
      preload: state => state.mobileFSFB.preloadPayments,
      env: state => state.env,
      fromQR: state => state.mobileFSFB.fromQR
    }),
    appointment() {
      let queryS = this.$route.query;
      if (queryS.reference_transaction)
        return this.citas.find(
          a =>
            a.number_form == queryS.reference_transaction.replace("appo-", "")
        ) || {};
      else return this.citas.find(a => a.number_form == queryS.id_cita_pago) || {};
    },
    doctorName() {
      if(this.appointment){
        let staff = this.appointment.staff;
        return staff ? `Dr(a). ${staff.name} ${staff.last_name} ${staff.mother_last_name}` : '';
      } else return "";
    }
  },
  created() {
    // preload
    this.handlePreloadAppointments(true);
  },
  mounted() {
    setTimeout(() => {

      // Check url payment
      if(this.appointment.appoinment_url_paid){
        this.urlExist = true;
      } else this.urlExist = false;

      // check time
      if(this.availableTime(this.appointment)){
        let queryS = this.$route.query;
        if (this.appointment.appoinment_paid == "SI")
          this.statusPayment = "success";
        if (queryS.status_transaction == "APPROVED")
          this.statusPayment = "success";
        if (queryS.status_transaction == "REVOKED") this.statusPayment = "error";
      } else {
        this.statusPayment = "outTime";
      }

      // preload
      this.handlePreloadAppointments(false);
    }, 2000);
  },
  methods: {
    ...mapActions({
      handlePreloadAppointments: "mobileFSFB/handlePreloadAppointments",
      setCitaCM: "mobileFSFB/setCitaCM",
      goToView: "mobileFSFB/goToView",
      goBack: "mobileFSFB/goBack",
    }),
    citaHora(hour) {
      //TODO: Por ahora esto no const dateCita = new Date(this.citasEnCM[0].Fecha).getHours();
      //TODO: Esta es forma de solo poner hora const splitFecha = this.citasEnCM[0].Fecha.split(":");
      // const result = `${splitFecha[0].slice(-2)}:${splitFecha[1]}`;
      return moment(hour).format("hh:mm a");
    },

    citaFecha(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    externalPay(appointment) {
      let url =
        appointment.appoinment_url_paid +
        "&return_url=" +
        `https://master.d2x3kt6co3jz8o.amplifyapp.com`;
      this.loading = "externalPay";
      window.location.href = url;
    },

    checkTurn() {
      this.loading = "checkTurn";
      let appointment = this.appointment;
      let queryS = this.$route.query;

      // check time
      if(!this.availableTime(appointment)){
        this.goToView({view: 'Home'});
        return this.$router.replace({ query: {  } });
      }

      // Check status transaction
      if(queryS.status_transaction == 'APPROVED'){
        appointment.appoinment_paid = 'SI';
        appointment.appoinment_url_paid = null;
      }
      if (this.statusPayment === "success") {
        this.setCitaCM(appointment);
      } else {
        this.statusPayment = null;
      }
    },

    availableTime() {
      return true;
      // let minutes = moment().diff(moment(`${cita.date_appoinment} ${cita.hour_appoinment}`), 'minutes');
      // minutes = minutes > 0 ? parseInt(minutes) : parseInt(minutes) * -1;
      // var isafter = moment().unix() - moment(`${cita.date_appoinment} ${cita.hour_appoinment}`).unix();
      // if(isafter > 0){
      //   return minutes <= this.env.VUE_APP_APPOINTMENT_TIME_AVAILABLE_AFTER;
      // } else return minutes <= this.env.VUE_APP_APPOINTMENT_TIME_AVAILABLE_BEFORE;
    },

    onSelectedThisProcedure(cita) {

      if(!this.availableTime(cita)) return;
      
      this.loading = "loadingSelect";
      this.selectedSchedule = cita;
      sessionStorage.setItem("appointment", cita.number_form);
      sessionStorage.setItem("fecha_cita", moment(`${cita.date_appoinment} ${cita.hour_appoinment}`).unix());
      sessionStorage.setItem("info_cita", JSON.stringify(cita));
      cita.goAsesor = true;
      this.setCitaCM(cita);
      
    },

    openAlert(){
      this.$bvModal.show("pays-modal-mobileFSFB");
    },

    cancelPayAction(){
      this.$bvModal.hide("pays-modal-mobileFSFB");
    }
  },

  components: {
    LoadingFSFB: () => import("./components/LoadingFSFB.vue"),
    MiniSpinner: () => import("./components/MiniSpinner")
  }
};
</script>

<style lang="scss" scoped>
.ContainerHeader {
  background-color: var(--color-radiant-blue);
  margin-bottom: -15px;
}

.fontTitleHeader {
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  padding-top: 18px;
  color: var(--color-white);
}

.ContainerData {
  position: relative;
  border-radius: 20px 20px 0 0;
  background-color: #ffffff;
  max-height: calc(100vh - 98px);
  overflow-y: auto;
}

.doctorName {
  font-size: 16px;
  line-height: 20px;
  color: #788591;
}

.dateCard {
  background: #daf1fb83;
  border-radius: 13px;
}

.buttonForm {
  height: 48px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  border-radius: 16px;
  transition: 0.5s all;
  background: var(--color-radiant);
  color: var(--color-white);
}

.card {
  max-width: 312px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(21, 21, 21, 0.02),
    0px 8px 12px rgba(21, 21, 21, 0.08);
  border-radius: 16px;
  border: none;
  padding: 16.22px;
}

.card-2 {
  margin-top: 36px;
  padding-bottom: 35px;
}

.card p {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  /* or 120% */

  /* UI Color/body_gray */

  color: #788591;
}

h3 {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */

  text-align: center;

  /* Text SubTitle */

  color: #283645;
}

.subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin: 0 24px;
  font-family: var(--font-secondary);
  color: var(--color-radiant-blue);
}

.Payment,
.ContainerData {
  height: 100%;
}

.backButton {
  padding-top: 21px;
}

.parragraphCapitalize {
  text-transform: capitalize;
}
</style>
